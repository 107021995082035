import React from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import Layout from '../../src/Layout';
import { getStore } from '../../src/Store';
import ActionCreator from '../../src/ActionCreator';
import Selector from '../../src/Selectors';
import { dispatchRouteInitClientActions } from '../../src/PageInitActions';
import * as Widget from '../../src/Components/Widget';
import MuiWrapper from '../../src/MuiWrapper';

class ConnectedPage extends React.Component {
  render() {
    let { profile, location} = this.props;
    let freeAccess = this._isFreeAccessPage(location.pathname);
    
    if (!profile && !freeAccess) {
      return (
      <div style={{
        width:'100vw',
        height:'100vh',
        display:'flex', 
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column', 
        backgroundColor:'#eee'
      }}>
        <MuiWrapper>
          <Widget.Spinner />
          <p style={{color:'#3f8dcf',margin:'30px 0'}}>檢查登入資料...</p>
        </MuiWrapper>
      </div>);
    }

    return (
      <Layout key={this.props.pageContext.lang} {...this.props}>
        {this.props.children}
      </Layout>
    );
  }

  componentWillMount() {
    // let appActions = ActionCreator(this.store.dispatch).appActions;
    let { appActions } = this.props;
    appActions.setLang(this.props.pageContext.lang);
  }

  async componentDidMount() {
    // await dispatchRouteInitClientActions({
    //   store: getStore(),
    //   location: this.props.location,
    // });
    let { appActions, profile , navActions, location} = this.props;

    let freeAccess = this._isFreeAccessPage(location.pathname);

    if (!profile) {
      await appActions.autoLogin();
      profile = await this.props.profile;
    }

    if (!profile && !freeAccess) {
      await navActions.push('/login');
    } 
  }

  _isFreeAccessPage = pathname => {
    let freeAccessPages = ['login','steps'];
    if(this.props.profile){
      freeAccessPages = freeAccessPages.filter(page => page === "login");
    }
    return freeAccessPages.some( page => pathname.indexOf(page) !== -1);
  }
}

ConnectedPage = connect(
  (state,ownProp) => ({
    profile : Selector.getLoginUser(state),
  }),
  ActionCreator
)(ConnectedPage)


class Page extends React.Component {
  render() {
    return (
      <Provider store={this.store}>
        <ConnectedPage {...this.props}>
          {this.props.children}
        </ConnectedPage>
      </Provider>
    );
  }

  componentWillMount() {
    console.log('initialState', this.props.pageContext.initialState);
    this.store = getStore(this.props.pageContext.initialState);
  }
}

export function withPage(Comp) {
  class PageWrapper extends React.Component {
    render() {
      return (
        <Page {...this.props}>
          <Comp {...this.props}>{this.props.children}</Comp>
        </Page>
      );
    }
  }

  PageWrapper.displayName = 'Page-' + Comp.displayName;
  return PageWrapper;
}

export default Page;
