const Settings = {
    ghPageCustomDomain: "tel25.revtel.tech",
    webUrl: "https://tel25.revtel.tech",
    apiUrl: "https://tel25-api.revtel.tech",
    workerPort: 12347,
    apiPrefix: "",
    SSR: true,
    cloudinaryUserId: "dfpqu00k2",
    debug: false,
    paypalSandboxEnv: true,
    paypalSandboxClientId: "AYCyvEJoKJ3378r-X2IozaB2HGknq7jd8U5aZjqF9jSl-Okj1kkIVNTpqBDej99zV4A2JQWzSjak7mOn",
    paypalProductionClientId: "???",
    facebookAppId: "236470817017403",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
    googleRecaptchaSiteKey: "6Lc-M3IUAAAAACf9jxpYI_ljJ3Pzq3AtlAO9ygJs",
    ECPAY_OP_MODE: "Production",
    ECPAY_LOGISTICS_MERCHANT_ID: "3081847",
};

module.exports = Settings;

