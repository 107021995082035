import React, { Component } from 'react';
import styled from 'styled-components';
import PathPrefix from '../../../src/Utils/PathPrefixUtil';
import LangSwitcher from '../../../src/Components/LangSwitcher';
import * as Widget from '../../../src/Components/Widget';
import Link from '../../../src/Components/Link';
import * as L from '../../../src/Utils/Lang';

class Footer extends Component {
  render() {
    let { extraCss = '' } = this.props;
    return (
      <Wrapper extraCss={extraCss} >
        <div className="info">
          <div className="content">
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link className="link" to="/privacy_policy">
                  {L.s('privacy_policy')}
                </Link>

                <Link className="link" to="/terms_of_service">
                  {L.s('terms_of_service')}
                </Link>

                <Link className="link" to="/steps">
                  {L.s('steps')}
                </Link>
              </div>
              <div>
                <p style={{ textAlign: 'center' }}>聯絡email: cs@globalstar-roaming.com</p>
              </div>

              {/* <div className="socials">
                <a
                  className="social-item"
                  target="_blank"
                  href='https://www.facebook.com/tel25limited/'
                >
                  <Widget.BgImage
                    extraCss="background-color: inherit;"
                    image={PathPrefix('/images/footer/fb.png')}
                  />
                </a>

                <a
                  className="social-item"
                  target="_blank"
                  href='https://line.me/R/ti/p/%40nzr0588p'
                >
                  <Widget.BgImage
                    extraCss="background-color: inherit;"
                    image={PathPrefix('/images/footer/line.png')}
                  />
                </a>

                <a
                  className="social-item"
                  target="_blank"
                  href='https://www.instagram.com/tel25limited/'
                >
                  <Widget.BgImage
                    extraCss="background-color: inherit;"
                    image={PathPrefix('/images/footer/ig.png')}
                  />
                </a>
              </div> */}
            </div>
          </div>
        </div>

        <div className="copyright">
          Copyright © 2019 Global star Company. All rights reserved
        </div>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background: rgb(92, 89, 89);
  color: white;
  font-size: 18px;
  text-align: justify;
  & > .info {
    padding: 50px 65px 45px;
    @media screen and (max-width: 479px) {
      padding: 36px;
    }
    @media screen and (max-width: 374px) {
      padding: 24px 12px;
    }

    & > .content {
      max-width: 950px;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      & .link {
        display: block;
        margin-bottom: 16px;
        color: inherit;
      }

      & .socials {
        display: flex;
        margin-top: 20px;
      }

      & .social-item {
        width: 50px;
        height: 50px;
        margin-left: 15px;
      }

      & .social-item:first-child {
        margin-left: 0;
      }
    }
  }

  & > .copyright {
    padding-bottom: 35px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

  @media screen and (max-width: 925px) {
    & > .info {
      & > .content {
        max-width: 445px;
        flex-direction: column;
        justify-content: flex-start;

        & > .first {
          width: 100%;
          margin-bottom: 45px;
        }

        & > .last {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;

    & > .info {
      & > .content {
        & .social-item {
          width: 35px;
          height: 35px;
          margin-right: 10px;
        }
      }
    }
  }
`;

export default Footer;
