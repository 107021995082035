import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ActionCreator from '../../../src/ActionCreator';
import * as L from '../../../src/Utils/Lang';
import PathPrefix from '../../../src/Utils/PathPrefixUtil';
import Link from '../../../src/Components/Link';
import * as Icon from '../../../src/Components/Icon';
import * as Widget from '../../../src/Components/Widget';
import { animateScroll as aniScroll } from 'react-scroll';
import selectors from '../../../src/Selectors';

const Breakpoint = 700;
const NavbarFooterState = {
  SHOW : 'SHOW',
  HIDE : 'HIDE',
  HIDE_ONLY_LINK:'HIDE_ONLY_LINK'
}
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rwdMenuOpen: false,
    };
  }

  _scrollSection = id => {
    let {navActions} = this.props;
    if (!this._scrollSectionImpl(id)) {
      navActions.push('/');
      setTimeout(() => this._scrollSectionImpl(id), 600);
    }
  }

  _scrollSectionImpl = id => {
    const element = document.getElementById(id);
    if (!element) {
      return false;
    }

    const targetHeight = element.offsetTop;
    this.setState({ rwdMenuOpen: false });
    aniScroll.scrollTo(targetHeight - 50, { duration: 400 } );
    return true;
  }

  _handleLogout = () => {
    let { navActions, appActions } = this.props;
    appActions.logout();
    navActions.push('/login') ;
  }

  render() {
    let { isNavbarTransparent, navbarHeight, navActions, appActions, navbarFooterState} = this.props;
    let { profile } = this.props;
    let { rwdMenuOpen } = this.state;

    return (
      <Navbar.Wrapper
        isNavbarTransparent={isNavbarTransparent}
        navbarHeight={navbarHeight}
        hidden={ navbarFooterState === NavbarFooterState.HIDE }
      >
        <Navbar.RowWrapper navbarHeight={navbarHeight}>
          <Link className="logo" to="/">
            <Widget.BgImage
              image={PathPrefix('/images/sp-logo/Global-Star.png')}
              color="transparent"
            />
          </Link>

          <div className="navs">
            { navbarFooterState === NavbarFooterState.HIDE_ONLY_LINK ? null :
            (
            <div className="nav-menus">
              <div className="nav-item" onClick={() => this._scrollSection('first_buy')}>首次購買</div>
              <div className="nav-item" onClick={() => this._scrollSection('deposit')}>加購</div>
              <div className="nav-item" onClick={() => this._scrollSection('check')}>查詢流量</div>
              <div className="nav-item" onClick={() => this._scrollSection('use_step')}>使用方法</div>
              <div className="nav-item" onClick={() => this._scrollSection('questions')}>常見問題</div>
              { profile && (
                <div className="nav-item" 
                onClick={this._handleLogout}>登出</div>
              )}

              <div className="nav-item" onClick={() => navActions.push("/checkout")}>
                <Icon.Cart color="white" style={{ width: 20, height: 20 }} />
              </div>
            </div>
            )}
            <div className="rwd-menu-toggle">
              <Widget.IconButton
                onClick={() => this.setState({ rwdMenuOpen: true })}
              >
                <Icon.Menu color={'white'} />
              </Widget.IconButton>
            </div>
          </div>
        </Navbar.RowWrapper>

        <Navbar.RwdMenuWrapper
          open={rwdMenuOpen}
          onClick={() => this.setState({ rwdMenuOpen: false })}
        >
          <Navbar.RwdMenus
            open={rwdMenuOpen}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <div className="menu-item" onClick={() => this._scrollSection('first_buy')}>首次購買</div>

            <div className="menu-item" 
                 onClick={() => this._scrollSection('deposit')}>加購</div>

            <div className="menu-item" 
                 onClick={() => this._scrollSection('check')}>查詢流量</div>

            <div className="menu-item" 
                 onClick={() => this._scrollSection('use_step')}>使用方法</div>

            <div className="menu-item" 
                 onClick={() => this._scrollSection('questions')}>常見問題</div>
            { profile && 
            <div className="menu-item" 
                  onClick={this._handleLogout}>登出</div>
            }
            <Widget.IconButton
              onClick={() => navActions.push("/checkout")} style={{ margin: "10px 0px 0px 20px"}}
            >
              <Icon.Cart color="white" />
            </Widget.IconButton>

            <Widget.IconButton
              onClick={() => this.setState({ rwdMenuOpen: false })}
              style={{ margin: "60px 0px 0px 20px", cursor: "pointer" }}
            >
              <Icon.Close color="white" />
            </Widget.IconButton>
          </Navbar.RwdMenus>
        </Navbar.RwdMenuWrapper>
      </Navbar.Wrapper>
    );
  }

  _isActiveRoute = routeRegexp => {
    let { pathname } = this.props;

    if (routeRegexp === '/') {
      return ['/', '/tw/', '/en/'].some(p => p === pathname);
    }

    return !!pathname.match(routeRegexp);
  };
}

Navbar.Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: ${props =>
    props.isNavbarTransparent ? 'rgba(0, 0, 0, .5)' : '#333333'};
  background-image: linear-gradient(#808080b0, rgba(0, 0, 0, 0));
  background-repeat: no-repeat;
  background-position: ${props =>
    props.isNavbarTransparent ? '0 0' : `0 -${props.navbarHeight}px`};
  padding: 0px 25px;
  z-index: 10;
  transition: background 200ms ease-in-out;
  display: ${props => props.hidden && 'none'};
`;

Navbar.RowWrapper = styled.div`
  margin: 0 auto;
  max-width: 1231px;
  height: ${props => props.navbarHeight}px;
  display: flex;
  align-items: center;

  & > .logo {
    width: 32px;
    height: 28px;
  }

  & > .navs {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & > .nav-menus {
      display: flex;

      & > .nav-item {
        margin-right: 25px;
        padding: 5px 11px;
        font-weight: 300;
        font-size: 14px;
        color: #f5f5f5;
        text-decoration: none;
        cursor: pointer;
      }

      & > .nav-item:last-child {
        margin-right: 0px;
      }

      & > .active {
        color: #0082f3
      }

      @media screen and (max-width: ${Breakpoint}px) {
        display: none;
      }
    }

    & > .rwd-menu-toggle {
      @media screen and (min-width: ${Breakpoint + 1}px) {
        display: none;
      }
    }
  }
`;

Navbar.RwdMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${props => (props.open ? '0' : '-100vw')};
  width: 100vw;
  height: 100vh;
  background-color: ${props =>
    props.open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'};
  transition: background-color 250ms linear,
    left 0ms linear ${props => (props.open ? '0ms' : '250ms')};
`;

Navbar.RwdMenus = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  width: 240px;
  height: 100%;
  background-color: #c8c8c8;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7));
  transform: ${props => (props.open ? 'none' : 'translate(-240px, 0px)')};
  transition: transform 250ms linear;

  & > .menu-item {
    margin-left: 20px;
    padding: 10px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default connect(
  (state,ownProps) => ({
    profile: selectors.getLoginUser(state)
  }),
  ActionCreator
)(Navbar);
