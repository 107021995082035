import React, { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import '../../src/index.css';
import PathPrefix from '../../src/Utils/PathPrefixUtil';
import Navbar from '../../src/Components/Navbar';
import Footer from '../../src/Components/Footer';
import MuiWrapper from '../../src/MuiWrapper';
import { connect } from 'react-redux';
import Selector from '../../src/Selectors';

const NavbarHeight = 50;
const FooterHeight = 369;
const NavbarFooterState = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
  HIDE_ONLY_LINK: 'HIDE_ONLY_LINK'
}

class Layout extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onScroll);
  }

  render() {
    let {
      location: { pathname },
    } = this.props;
    pathname = this._normalizePath(pathname);

    return (
      <MuiWrapper>
        <Layout.Wrapper
          isNavbarTransparentInPage={this._isNavbarTransparentInPage()}
        >
          <Helmet>
            <title>全球之星</title>
            <meta name="description" content="TEL25 二十五電訊" />
            <link rel="shortcut icon" href={PathPrefix('/Global-Star.ico')} />
          </Helmet>

          <Navbar
            isNavbarTransparent={
              this._isNavbarTransparentInPage() && !this.passBreakpoint
            }
            navbarHeight={NavbarHeight}
            pathname={pathname}
            navbarFooterState={this._getNavbarFooterState()}
          />

          <Layout.Content>{this.props.children}</Layout.Content>
          {
            this._getNavbarFooterState() === NavbarFooterState.SHOW &&
            <Footer />
          }
        </Layout.Wrapper>
      </MuiWrapper>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === '//') {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== '/') {
      path += '/';
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop =
      window.pageYOffset ||
      (document.documentElement && document.documentElement.scrollTop) ||
      (document.body && document.body.scrollTop) ||
      0;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        console.log('+++ breakpoint +++');
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        console.log('--- breakpoint ---');
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };

  _isNavbarTransparentInPage = () => {
    let {
      location: { pathname },
    } = this.props;
    pathname = this._normalizePath(pathname);

    if (pathname.slice(-4) === '/me/') {
      return false;
    } else if (pathname.slice(-6) === '/cart/') {
      return false;
    } else if (pathname.slice(-10) === '/checkout/') {
      return false;
    } else if (pathname.slice(-7) === '/order/') {
      return false;
    } else if (pathname.slice(-5) === '/cvs/') {
      return false;
    } else if (pathname.slice(-5) === '/faq/') {
      return false;
    } else if (pathname.slice(-16) === '/privacy_policy/') {
      return false;
    } else if (pathname.slice(-18) === '/terms_of_service/') {
      return false;
    } else if (pathname.slice(-12) === '/validation/') {
      return false;
    } else if (pathname.slice(-9) === '/expired/') {
      return false;
    } else if (pathname.indexOf('social-login-result') > -1) {
      return false;
    }

    return true;
  };

  _getNavbarFooterState = () => {
    let {
      location: { pathname },
      profile
    } = this.props;
    pathname = this._normalizePath(pathname);

    if (pathname.slice(-7) === "/steps/") {
      return NavbarFooterState.HIDE_ONLY_LINK;
    }
    if (!profile) {
      return NavbarFooterState.HIDE;
    }
    return NavbarFooterState.SHOW;
  }
}

Layout.Wrapper = styled.div`
  min-height: 100vh;
  background-color: white;
  padding-top: ${props =>
    props.isNavbarTransparentInPage ? '0' : `${NavbarHeight}`}px;
  display: flex;
  flex-direction: column;
`;

Layout.Content = styled.div`
  min-height: calc(100vh - ${NavbarHeight}px - ${FooterHeight}px); // for IE11
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selector.getLoginUser(state),
  })
  , null
)(Layout)